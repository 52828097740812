import React from 'react'
import Layout from "../components/layout";
import { StaticImage } from "gatsby-plugin-image"
import { Helmet } from 'react-helmet';

function Fotografie() {

  const sitename = "Bart Sallé, freelance webdeveloper";
  const pagetitle = "Fotografie - Bart Sallé, freelance webdeveloper";
  const description = "Fotografie is al jaren mijn tweede passie. Ik ben gespecialiseerd in interieur- en bedrijfsfotografie en help je hiermee graag verder!";
  const url = "https://www.bartsalle.nl/fotografie/";

  return (
    <Layout>
      <Helmet>
        <title>{pagetitle}</title>
        <link rel="canonical" href={url} />
        <meta name="description" content={description} />
        <meta property="og:locale" content="nl_NL" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={pagetitle} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={url} />
        <meta property="og:site_name" content={sitename} />
        <body className="fotografie" />
      </Helmet>
        <div className="container">
          <h1>Fotografie</h1>
          <p>Fotografie is al jaren mijn tweede passie. Ik ben gespecialiseerd in interieur- en bedrijfsfotografie. Wil je een goede presentatie van je huis of bedrijfspand voor Funda? Of wellicht foto’s voor op de website? Ik help je graag verder!</p>
          <p>Buiten interieur- en bedrijfsfotografie fotografeer ik graag landschappen en steden. Mijn volledige portfolio vind je terug op mijn fotografiesite.</p>
          <p><a href="https://photography.bartsalle.nl/" rel="nofollow" target="_blank" className="button">Bezoek mijn fotografie site</a></p>
          <h2>Websites voor fotografen en kunstenaars</h2>
          <p>Zelf een online portfolio nodig? Kijk eens op <a href="https://www.mijnwerkonline.nl/" rel="nofollow" target="_blank" alt="Mijn Werk Online website">mijnwerkonline.nl</a>. Je online portfolio, zowel website als app!</p>
          <a href="https://www.mijnwerkonline.nl/" target="_blank" alt="Mijn Werk Online website"><StaticImage src="../images/logo-mijn-werk-online.png" alt="Mijn Werk Online" layout="fixed" width={360} placeholder="none" /></a>
        </div>
    </Layout>
  )
}

export default Fotografie